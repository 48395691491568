import jsonp from "jsonp";

const mailchimpSubscribeURL =
  "https://pannalvillagehall.us14.list-manage.com/subscribe/post-json?u=fceb5e7574e45d6ef6b23d389&amp;id=849c06ef67&amp;f_id=006bece0f0";

export const subscribe = (formData, callback) => {
  console.log(`formData = ${formData}`);
  let url =
    mailchimpSubscribeURL +
    "&" +
    Object.entries(formData)
      .filter((e) => e[1] !== undefined)
      .map((e) => `${encodeURIComponent(e[0])}=${encodeURIComponent(e[1])}`)
      .join("&");
  jsonp(url, { param: "c" }, (err, data) => {
    if (err) {
      callback({ status: "error", data: err });
    } else if (data.result !== "success") {
      callback({ status: "error", data });
    } else {
      callback({ status: "success", data });
    }
  });
};

export default { subscribe };
