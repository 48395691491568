import React from "react";
import { Form, Field } from "react-final-form";
import Mailchimp from "../actions/mailchimp";

import {
  mailingListSubmissionFailure,
  mailingListSubmissionSuccess,
  mailingListSignupForm,
} from "../css/mailinglistsignup.module.css";

const initialState = {
  state: "initial", // initial | submitting | error | success
  error: null, // non-null if state = error
  mailchimpMessage: null, // non-null if state = success
};

const MailingListSignup = () => {
  const [state, setState] = React.useState(initialState);
  const errorMessage =
    state.state === "error"
      ? state.error.msg != null
        ? state.error.msg.slice(4)
        : state.error.message
      : null;
  const onSubmit = (values, form) => {
    setState({ state: "submitting", error: null, mailchimpMessage: null });
    Mailchimp.subscribe(values, ({ status, data }) => {
      if (status === "error") {
        setState({ state: "error", error: data, mailchimpMessage: null });
      } else {
        setState({ state: "success", error: null, mailchimpMessage: data.msg });
      }
    });
  };
  return (
    <React.Fragment>
      <h2 id="mailing-list-subscribe">Our mailing list</h2>
      <p>
        Subscribe to our mailing list to hear about events – you can opt out at
        any time.
      </p>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, form, values }) => (
          <form onSubmit={handleSubmit} className={mailingListSignupForm}>
            <div>
              <label htmlFor="signup-email">Email (required)</label>
              <Field
                name="EMAIL"
                component="input"
                type="email"
                id="signup-email"
                required
              />
            </div>
            <div>
              <label htmlFor="signup-first-name">First name</label>
              <Field name="FNAME" component="input" id="signup-first-name" />
            </div>
            <div>
              <label htmlFor="signup-last-name">Last name</label>
              <Field name="LNAME" component="input" id="signup-last-name" />
            </div>
            <button
              type="submit"
              className="btn-primary"
              disabled={state.state === "submitting"}
            >
              Subscribe
            </button>
          </form>
        )}
      />
      {state.state === "success" ? (
        <div className={mailingListSubmissionSuccess}>
          <p>{state.mailchimpMessage}</p>
        </div>
      ) : (
        <React.Fragment></React.Fragment>
      )}
      {state.state === "error" ? (
        <div className={mailingListSubmissionFailure}>
          <p>
            Failed to subscribe you to our mailing list.
            {errorMessage != null ? ` ${errorMessage}.` : ""}
          </p>
        </div>
      ) : (
        <React.Fragment></React.Fragment>
      )}
    </React.Fragment>
  );
};

export default MailingListSignup;
