import React from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";

import GetInTouch from "../components/contact/contactUs";
import FindUs from "../components/contact/findUs";
import History from "../components/about/history";
import MailingListSignup from "../components/mailingListSignup";
import Volunteers from "../components/about/volunteers";

import {
  mailingListSignupWrapper,
  aboutContent,
} from "../css/about.module.css";

const About = ({ data }) => {
  return (
    <>
      <Helmet>
        <title>About us | Pannal Village Hall</title>
      </Helmet>
      <div className="page-header">
        <h1>About us</h1>
      </div>
      <div className={aboutContent}>
        <FindUs address={data.strapiContactUs} />
        <section>
          <h2>Get in Touch</h2>
          <GetInTouch
            tel={data.strapiContactUs.telephoneNumber}
            bookingContacts={data.allStrapiBookingContacts.edges.map(
              (item) => item.node
            )}
            showSocial={true}
          />
        </section>
        <section className={mailingListSignupWrapper}>
          <MailingListSignup />
        </section>
        <section>
          <h2>Safeguarding Policy</h2>
          <p>
            As a registered charity, we are required by the Charity Commission
            to have a safeguarding policy which applies to everyone using the
            hall or working or volunteering for the charity. You can download
            the policy <a href={data.safeguardingPolicy.pdfUrl}>here</a>.
          </p>
        </section>
        <section>
          <h2>Equality and Diversity Policy</h2>
          <p>
            Our commitment to equality and diversity is set out in our policy
            document, which you can download{" "}
            <a href={data.equalityAndDiversityPolicy.pdfUrl}>here</a>.
          </p>
        </section>
        <section>
          <h2>Privacy Policy</h2>
          <p>
            Our privacy policy outlines our data protection policy and
            procedures. You can download it{" "}
            <a href={data.privacyPolicy.pdfUrl}>here</a>.
          </p>
        </section>
        <History history={data.history} />
        <Volunteers volunteers={data.volunteers} />
      </div>
    </>
  );
};

export default About;

export const query = graphql`
  {
    strapiContactUs {
      addressText
      contactText
      telephoneNumber
    }
    history: strapiAbout {
      id
      aboutus
      aboutimage {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
        }
      }
    }
    equalityAndDiversityPolicy: strapiEqualityAndDiversityPolicy {
      pdfUrl
    }
    volunteers: allStrapiVolunteers {
      edges {
        node {
          id
          name
          role
          image {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
    privacyPolicy: strapiPrivacy {
      pdfUrl: privacyPolicyURL
    }
    safeguardingPolicy: strapiSafeguardingPolicy {
      pdfUrl
    }
    allStrapiBookingContacts {
      edges {
        node {
          name
          phoneNumber
        }
      }
    }
  }
`;
