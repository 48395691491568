import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

import {
  volunteerDetails,
  volunteerImage,
  volunteerCard,
} from "../../css/volunteer.module.css";

const Volunteer = ({ volunteer }) => {
  return (
    <div className={volunteerCard}>
      <div className={volunteerImage}>
        <GatsbyImage
          image={volunteer.image.childImageSharp.gatsbyImageData}
          alt={volunteer.name}
          imgStyle={{ objectFit: "cover" }}
          style={{ height: "174px", width: "171px" }}
        />
      </div>
      <div className={volunteerDetails}>
        <h3>{volunteer.name}</h3>
        <p>{volunteer.role}</p>
      </div>
    </div>
  );
};

export default Volunteer;
