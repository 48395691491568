import React from "react";
import Volunteer from "./volunteer";

import {
  committeeWrapper,
  volunteersWrapper,
} from "../../css/volunteers.module.css";

const Volunteers = ({ volunteers }) => {
  return (
    <div className={volunteersWrapper}>
      <h2>Your volunteer committee</h2>
      <div className={committeeWrapper}>
        {volunteers.edges.map((item) => (
          <Volunteer volunteer={item.node} key={item.node.id} />
        ))}
      </div>
    </div>
  );
};

export default Volunteers;
