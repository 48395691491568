import React from "react";
import ReactMarkdown from "react-markdown";
import { GatsbyImage } from "gatsby-plugin-image";

import {
  historyTextWrapper,
  historyImageWrapper,
  historyWrapper,
  historyTopWrapper,
} from "../../css/history.module.css";

const History = ({ history }) => {
  return (
    <div className={historyTopWrapper}>
      <h2>Our History</h2>
      <div className={historyWrapper}>
        <div className={historyImageWrapper}>
          <GatsbyImage
            image={history.aboutimage.childImageSharp.gatsbyImageData}
            alt="old village hall"
            imgStyle={{ objectFit: "cover" }}
            style={{ height: "178px" }}
          />
        </div>
        <div className={historyTextWrapper}>
          <ReactMarkdown source={history.aboutus} />
        </div>
      </div>
    </div>
  );
};

export default History;
