import React from "react";
import ReactMarkDown from "react-markdown";

import {
  addressMap,
  addressDetails,
  addressMapWrapper,
  findUsWrapper,
} from "../../css/find-us.module.css";

const FindUs = ({ address }) => {
  return (
    <div className={findUsWrapper}>
      <h2> Find us</h2>
      <div className={addressMapWrapper}>
        <div className={addressDetails}>
          <address>
            <ReactMarkDown source={address.addressText} />
          </address>
          <ReactMarkDown source={address.contactText} />
        </div>
        <div className={addressMap}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2347.5148849576753!2d-1.5336214!3d53.9581239!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48795116536b5d87%3A0x5a2057dbfabdaf63!2sPannal%20Village%20Hall!5e0!3m2!1sen!2suk!4v1592567024870!5m2!1sen!2suk"
            title="Pannal Village Hall location"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default FindUs;
